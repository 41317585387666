<template>
  <div>
    <el-button
      type="primary"
      :disabled="disabled"
      :size="size"
      @click="preSubmit"
    >
      <slot>
        <font-awesome-icon :icon="['fas', 'pen']" class="mr-1" />
        開始測驗
      </slot>
    </el-button>
    <Replace-Confirm :test-id="recordTestId" @submit="submit"></Replace-Confirm>
  </div>
</template>

<script>
import { h, inject, toRefs, ref, provide } from 'vue';

import Tester from '@/api/Tester';
import { useRouter } from 'vue-router';
import { ElMessage, ElRadioGroup, ElRadio } from 'element-plus';
import MessageBox from '@/components/messageBox/Index';
import { LIMIT } from '@/utils/Config.js';
import useDialog from '@/hook/useDialog';
import ReplaceConfirm from './_ReplaceConfirm.vue';
import useConfirmMsg from './useConfirmMsg.js';
export default {
  components: { ReplaceConfirm },
  props: {
    size: {
      type: String,
      default: 'default',
    },
    type: {
      type: String,
      default: null,
    },
    filterId: {
      type: String,
      default: null,
    },
    folderId: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    emKeys: {
      type: Array,
      default: () => [],
    },
    order: {
      type: [Number, String],
      default: '',
    },
    orderItem: {
      type: String,
      default: '',
    },
    needPickOrder: {
      type: Boolean,
      default: false,
    },

    needConfirm: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    // message: {
    //   type: String,
    //   default: '',
    // },
  },
  setup(props) {
    const router = useRouter();

    const {
      total,
      folderId,
      filterId,
      type,
      emKeys,
      order,
      orderItem,
      needConfirm,

      needPickOrder,
    } = toRefs(props);
    const pickOrder = ref('order');
    const auth = inject('auth');
    const permission = inject('permission');
    const dialog = useDialog();
    provide('dialog', dialog);
    const recordTestId = ref(null);
    const preSubmit = async () => {
      if (!auth.guard()) return;
      const res = await Tester.check();

      if (res.code === 1) {
        dialog.toggle({ show: true });
        recordTestId.value = res.data.testId;
      } else {
        submit();
      }
    };

    const submit = async () => {
      dialog.toggle({ show: false });
      try {
        let confirm = true;

        if (
          confirm &&
          needPickOrder.value &&
          !filterId.value &&
          total.value > LIMIT
        ) {
          confirm = await MessageBox.confirm('', '', 'alert', {
            showClose: false,
            showCancelButton: true,
            closOnClickModal: false,
            message: h('div', null, [
              h('div', { class: 'text-2xl font-bold mt-2' }, '要寫那些題目?'),
              h('div', { class: 'mt-3' }, '此次測驗試題上限題數為250題。'),

              h(
                ElRadioGroup,
                {
                  modelValue: pickOrder,
                  class: 'mt-8',
                  'onUpdate:modelValue': (val) => {
                    pickOrder.value = val;
                  },
                },
                () => [
                  h(
                    ElRadio,
                    {
                      label: 'order',
                    },
                    () => ['按列表順序挑選'],
                  ),
                  h(
                    ElRadio,
                    {
                      label: 'random',
                    },
                    () => ['隨機挑選'],
                  ),
                ],
              ),
            ]),
          });
        }

        if (confirm && needConfirm.value) {
          confirm = await useConfirmMsg();
        }

        if (confirm) {
          const params = Object.fromEntries(
            Object.entries({
              type: type.value,
              emKeys: emKeys.value?.length ? emKeys.value : null,
              folderId: folderId.value,
              filterId: filterId.value,

              order: order.value,
              orderItem: orderItem.value,
              pickOrder: pickOrder.value,
            }).filter(([_, v]) => v != null),
          );

          Tester.add({ ...params }).then(async (res) => {
            if (
              !(await permission.dialog(
                res.status == 402 ? '0' : '1',
                '1.沒有啟用任何方案。 \n 2.選擇的內容不包括在啟用方案中。',
              ))
            )
              return;

            if (res.code === 1) {
              router.push({
                name: 'Tester',
                params: { testId: res.data.testId },
              });
            } else {
              ElMessage({ message: res.message, type: 'error' });
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    return { preSubmit, submit, pickOrder, dialog, recordTestId };
  },
};
</script>
